
import CalendlyInline from "./sub-components/buttons/CalendlyInline";


const Contact = () => {
	const styles = {minHeight: '120vh'}

    return <>
		<div style={styles}>
			<section>
				<CalendlyInline />
			</section>
		</div>
	</>;
}

export default Contact;
