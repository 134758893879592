
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from "../../assets/styles";
import { Table, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";


export default function CompTable() {
    // define styles by lightMode
    const { lightMode } = useThemeContext();

    const style = {fontSize: '13px'};
    const checkedColor = {color: '#beb44c'};
    const info = 'info', light = 'light', dark = 'dark';
    const { fontColor } = switchTheme(lightMode);


    return <>
        <Container className={`py-5 bg-${lightMode ? light : light}`}>
            <h1 className="text-center" style={fontColor}>Compare Our Software Packages</h1>

            <Table responsive striped hover variant={lightMode ? light : dark} className="">
                <thead className="border-light">
                    <tr>
                        <th></th>
                        <th>
                            <strong>Tax Affiliate</strong>
                        </th>
                        <th>
                            <strong>Tax Office</strong>
                        </th>
                        <th>
                            <strong>Mentorship</strong>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">Split Prep-Fees</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Basic Tax Training</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">EFIN & EIN Application Walkthrough</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Top Tier Tech Support</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Unlimited E-File</th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Free Software Setup
                            <h5 style={style}>- Back Office Setup</h5>
                            <h5 style={style}>- Bank Product Application(s)</h5>
                            <h5 style={style}> - Taxes-To-Go App Setup</h5>
                            <h5 style={style}> - Additional User Setup</h5>
                            <h5 style={style}> - Security Templates For Employees/Affiliates</h5>
                            <h5 style={style}> - Any Other Additional Setup</h5>
                        </th>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Unlimited Number Of Users</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Business Desktop Software</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Taxes To Go App With Your Logo</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Marketing & Recruiting Training 101</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Business Coaching & Mentorship</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Advanced Marketing & Recruiting Strategies</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Advanced Internet & Social Media Strategies</th>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faTimes} className="text-danger" />
                        </td>
                        <td>
                            <FontAwesomeIcon icon={faCheck} style={checkedColor} />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    </>;
}
