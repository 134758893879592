
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about1 from '../../assets/images/about-1.webp';
// import about2 from '../../assets/images/about-2.webp';


const AboutUs = () => {
	// define style by lightMode
	const { lightMode } = useThemeContext();
	const { aboutUsTextColor } = switchTheme(lightMode);

	// const style = {color: '#0e65d7'};
	// const sm = '12', lg = '6'; // setting for 2 people about page
	const styles = { margin:  "auto", width:'17%'};
	const space = 3;

    return (
      <>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 style={aboutUsTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className="justify-items-center justify">
				<Row className="justify-items-center">
					<Col> {/* setting for 1 person about page */}
					{/* <Col sm={sm} lg={lg}> */} {/* setting for 2 people about page */}
						<Row>
							<div className={`pt-${space}`}>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about1}
									alt="First slide"
								/>
								<h4 className={`text-center`} style={aboutUsTextColor}>
								Hi I'm Nytasha!
								</h4>
								<p>
									When it comes to your finances and tax business, our team of devoted and passionate tax professionals is always here to assist you. Our mission is to deliver top-notch tax services that you can depend on. We provide our clients with the greatest tax software options available.
								</p>
								<p>
									Having worked in the tax profession for many years, we have earned the respect of numerous people and companies. Our team of professionals possesses the requisite expertise, abilities, and technological tools to deliver precise and effective tax solutions that satisfy your requirements. We take pleasure in providing each of our clients with individualized services that help them achieve their financial objectives and take care of any tax-related problems.
								</p>
								<p>
									In addition to providing excellent tax services, we also provide the greatest tax software, which makes tax preparation easier. Because of our software's effectiveness, adaptability, and ease of use, you may concentrate on other facets of your company. 
								</p>
							</div>
						</Row>
					</Col>
					{/* <Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${aboutUsTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about2}
									alt="First slide"
								/>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
							</div>
						</Row>
					</Col> */}
						<Container className="text-center">
							{/* <h5 className={`text-center ${aboutUsTextColor}`}>
								Our Commitment to You!
							</h5> */}
							{/* <p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p> */}
							<h5 className={`text-center`} style={aboutUsTextColor}>
								Our Commitment to You!
							</h5>
							<p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p>
					</Container>
					<NavLink
						to="/business-opportunities"
						className={`mt-${space} btn ${ lightMode ? 'btn-warning' : 'btn-outline-warning'}`}
						style={{maxWidth: '300px', margin: 'auto'}}
					>
						Join Our Team
					</NavLink>
					<NavLink
						to="/contact"
						className={`mt-${space} btn ${ lightMode ? 'btn-success' : 'btn-outline-success'}`}
						style={{maxWidth: '300px', margin: 'auto'}}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;
