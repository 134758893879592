
// define style by lightMode
export function switchTheme(lightMode) {
    const sun = "rgb(211, 165, 47)";
    const altblue = 'rgb(20, 104, 238)';
    const whiteSmoke = 'whitesmoke';
    const blue = 'rgb(41, 116, 237)';
    const btnLight = 'btn-light';
    const dark = blue;
    const light = '#d8e0e7'; // bootstrap $light
    const darkerWhite = 'rgb(249, 253, 255)';
    const red = 'rgb(237, 71, 41)';
    const burgundy = '#530617';
    // const warning = 'rgb(211, 165, 47)'; // bootstrap btn-warning color code
    const warning = '#ffc107'; // bootstrap btn-warning color code
    const teal = '#008080';
    const pinkish = '#b046d4';
    const golden = '#beb44c';
    const gray700 = '#495057';
    const greenish = '#00cc99';

    return {
        fontColor: {color: lightMode ? golden : golden},
        advantagesIconColor: { color: lightMode ? golden : golden, backgroundColor: lightMode ? light : light },
        // advantagesBackgroundColor: { backgroundColor: lightMode ? golden : golden },
        bg: lightMode ? 'light' : 'light',
        borderTop: { borderTop: lightMode ? ` 1px solid ${teal}` : ` 1px solid ${teal}` }, // border below the navbar
        buttonColors: {
            red: red,
            blue: blue,
            altblue: altblue,
            btnLight: btnLight,
            teal: teal,
            warning: warning,
            whiteSmoke: whiteSmoke,
            pinkish: pinkish,
            greenish
        },
        cardTextColor: {color: lightMode ? golden : golden},
        textColor: {color: lightMode ? whiteSmoke : whiteSmoke},
        color: lightMode ? 'dark' : 'dark',
        funFactsBackgroundColor: lightMode ? 'bg-info' : 'bg-light',
        backgroundColor: lightMode ? 'bg-light' : 'bg-dark',
        // backgroundColor: lightMode ? 'bg-dark' : 'bg-light',
        dealStyles: { color: lightMode ? whiteSmoke : whiteSmoke, },
        formLabelstyle: lightMode ? 'backgroundColor : bg-body' : 'backgroundColor: bg-success',
        // formLabelstyle: lightMode ? 'backgroundColor : bg-success' : 'backgroundColor: bg-body',
        funFactIconColor: { color: lightMode ? golden : golden, },
        // funFactIconColor: { color: lightMode ? '#0d0d0e' : '#0d0d0e', },
        iconStyle: { fontSize: '49px', color: lightMode ? sun : sun, },
        // mainBannerTextColor: lightMode ? {color: golden} : {color: whiteSmoke},
        mainBannerTextColor: lightMode ? {color: golden} : {color: whiteSmoke},
        aboutUsTextColor: lightMode ? {color: golden} : {color: whiteSmoke},
        PayPalButtonsStyle: lightMode ? 'white' : 'black',
        // PayPalButtonsStyle: lightMode ?  'black': 'white',
        // pricingBackgroundColor: lightMode ? 'color: bg-success' : 'color: bg-body',
        pricingBackgroundColor: lightMode ? 'color: bg-body' : 'color: bg-success',
        shadow: lightMode ? "shadow-sm" : "shadow",
        // shadow: lightMode ? "shadow" : "shadow-sm",
        text: lightMode ? 'text-dark' : 'text-dark',
        theme: lightMode ? 'bg-light' : 'bg-dark',
        variant: lightMode ? 'light' : 'light',
        // theme: lightMode ? 'bg-dark' : 'bg-light',
        // text: lightMode ? 'text-dark' : 'text-dark',
        // variant: lightMode ? 'light' : 'light',
    };
};