
import { useLocation } from 'react-router-dom';
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Icon } from "@iconify/react";
import ThemeSwitch from '../sub-components/buttons/ThemeSwitch';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import Logo from '../../assets/images/logo.webp';


export default function NavBar() {
  
  // define button style by lightMode
  const { lightMode } = useThemeContext();
  const { variant, bg, iconStyle, fontColor, buttonColors } = switchTheme(lightMode);
  
  const location = useLocation(); // Get current location

  
  return <>
    <div className='pb-3 mt-4'>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg={bg} variant={variant} className='border-bottom border-dark'>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={Logo}
              width={"45%"}
              alt="Company Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" expand='md'>
            <Nav 
              variant="underline" 
              activeKey={location.pathname} 
              className="me-auto"
            >
              <Nav.Item>
                <Nav.Link style={fontColor} href="/">Welcome</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link style={fontColor} eventKey={"/business-opportunities"} href="/business-opportunities">Business Opportunities</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link style={fontColor} eventKey={"/about"} href="/about">About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link style={fontColor} eventKey={"/contact"} href="/contact">Contact Us</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link style={fontColor} eventKey={"/make-payments"} href="https://buy.stripe.com/bIY4gQ9LMfnn6ME5kk" target="_blank" rel='noreferrer'>
                  Make Payments
                </Nav.Link>
              </Nav.Item> */}
              {/* <Nav.Link style={buttonColors} eventKey={"/ready-to-file"} href="https://taxestogo.com/App/Download/90491" target="_blank" rel='noreferrer'>
                Ready To File!
              </Nav.Link> */}
            </Nav>
            <Nav className="font-weight-light mx-2">
              <Nav.Link eventKey={"/face-book"} href={process.env.REACT_APP_FB} target="_blank" rel='noreferrer'>
                <Icon icon="iconoir:facebook-squared" style={ iconStyle }/>
              </Nav.Link>
              <Nav.Link eventKey={"/instagram"} href={process.env.REACT_APP_IG} target="_blank" rel='noreferrer'>
                <Icon icon="ph:instagram-logo" style={ iconStyle }/>
              </Nav.Link>
            </Nav>
            <Nav>
              <ThemeSwitch />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </>;
}
