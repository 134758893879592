
import React, { useEffect, useState } from "react";
import { useThemeContext } from "../../../hooks/usethemeContext";
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Icon } from '@iconify/react';


export default function ThemeSwitch() {

  // const [radioValue, setRadioValue] = useState('1');
  const { lightMode, setLightMode } = useThemeContext();
  const [checked, setChecked] = useState(lightMode);
  
  document.querySelectorAll('[data-toggle="tooltip"]')
  
  
  // useEffect(() => {
    // if(lightMode){
    //   document.querySelector('html').setAttribute("class", "bg-dark");
    // } else {
    //   document.querySelector('html').setAttribute("class", "bg-light");
    // };
    // console.log(lightMode)
  // }, );
  // }, [lightMode]);
  
    const iconClass = {
        border: lightMode ? 'border-dark': 'border-warning',
        height: '12px',
    };
  
  const iconColor = { color: lightMode ? 'black' : 'rgb(211, 165, 47)' };
  const SunMoon = lightMode ? <Icon style={ iconColor } icon="ri:moon-clear-fill" /> : <Icon style={ iconColor } icon="ph:sun-fill" />;
  // const SunMoon = lightMode ? <Icon style={ iconColor } icon="ph:sun-fill" /> : <Icon style={ iconColor } icon="ri:moon-clear-fill" />;

  const switchTheme = (e) => {
    setChecked(e.currentTarget.checked);
    setLightMode((prev) => !prev);
    // setLightMode((lightMode) => !lightMode);
  };

  return <>
      <ToggleButton
        id="toggle-check"
        type="checkbox"
        className={`border ${iconClass.border} border-2 rounded`}
        variant={null}
        checked={checked}
        value="1"
        onChange={switchTheme}

        // custom-tooltip
        trigger='hover'
        delay={{"show": 0, "hide": 250}}
        html={'true'}
        data-toggle="tooltip"
        data-placement="auto"
        title="Light/Dark Mode"
      >
        { SunMoon } {/*light / dark theme icons  */}
      </ToggleButton>
  </>;
};
