
export const dealType = {
  taxAffiliate1: 'Tax Preparer Professional',
  taxAffiliate2: 'Tax Preparer Deluxe',
  taxAffiliate3: 'Tax Preparer Premium',
  taxOffice: 'Tax Office',
  mentorship: 'Mentorship',
  bookkeeping: 'Bookkeeping',
};

export function getTaxSoftwareText(
  promo,
  earlySpecialHTML,
  regularPrice,
  taxOfficeRegularPrice,
  taxAffiliateRegularPrice1,
  taxAffiliateRegularPrice2,
  taxAffiliateRegularPrice3,
  mentorshipRegularPrice,
  bookkeepingRegularPrice
) {

  const financingText = <div>
    <div className="d-flex justify-content-center align-items-center">
      <div className="text-start">
        <li>Our Easy Check Out:</li>
        <li>- Buy now, pay later</li>
        <li>- Financing Available</li>
        <li>- As low as 0% APR</li>
      </div>
    </div>
    <div className="fs-6 text-warning">
      <li className="mt-2">Click Affirm or AfterPay</li>
      <li>at checkout for offers</li>
    </div>
  </div>

  
return {
    taxAffiliate1: 
      <>
          {promo ? 
          <> <p>{`${regularPrice} $${taxAffiliateRegularPrice1}`}</p>
              {earlySpecialHTML}
          </>
          : 
          <></>
          }
          <p>1 Tax Office User ID</p>
          <p>
          <strong>50/50</strong> Split Prep-Fees
          </p>
          <p>Basic Tax Training</p>
          <p>Top Tier Tech Support</p>
          {financingText}
      </>
      ,
    taxAffiliate2: 
      <>
          {promo ? 
          <> <p>{`${regularPrice} $${taxAffiliateRegularPrice2}`}</p>
              {earlySpecialHTML}
          </>
          : 
          <></>
          }
          <p>1 Tax Office User ID</p>
          <p>
          <strong>60/40</strong> Split Prep-Fees
          </p>
          <p>Basic Tax Training</p>
          <p>Top Tier Tech Support</p>
          {financingText}
      </>
      ,
    taxAffiliate3: 
      <>
          {promo ? 
          <> <p>{`${regularPrice} $${taxAffiliateRegularPrice3}`}</p>
              {earlySpecialHTML}
          </>
          : 
          <></>
          }
          <p>1 Tax Office User ID</p>
          <p>
          <strong>No</strong> Split Prep-Fees
          </p>
          <p>Basic Tax Training</p>
          <p>Top Tier Tech Support</p>
          {financingText}
      </>
      ,
      taxOffice: 
      <>
        {promo ? 
        <><p>{`${regularPrice} $${taxOfficeRegularPrice}`}</p>
            {earlySpecialHTML}
        </>
        : 
        <></>
        }
        <p><strong>Unlimited</strong> Number Of Users</p>
        <p>Hire Preparers</p>
        <p>Branded Taxes To Go App</p>
        <p>Top Tier Tech Support</p>
        {financingText}
      </>
      ,
      mentorship: 
      <>
        {promo ? 
          <><p>{`${regularPrice} $${mentorshipRegularPrice}`}</p>
          {earlySpecialHTML}
          </>
        : 
          <></>
        }
        <p>Software + Mentorship</p>
        <p>Hire Preparers</p>
        <p><strong>Marketing</strong> Training</p>
        <p ><strong>Sales</strong> Training</p>
        {financingText}
      </>
      ,
      bookkeeping: 
      <>
        {promo ? 
          <><p>{`${regularPrice} $${bookkeepingRegularPrice}`}</p>
          {earlySpecialHTML}
          </>
        : 
          <></>
        }
        <p>Professional Bookkeeping</p>
        {/* <p style={mentorshiptStyle}><strong>+ Added Value:</strong></p> */}
        <p><strong>Upload</strong> Your Copies</p>
        <p>We Keep You On Track</p>
        <p ><strong>Make</strong> Informed Decisions </p>
        {financingText}
      </>
      ,
  };
}