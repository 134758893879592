
import { useThemeContext } from "../../../hooks/usethemeContext";
import { InlineWidget } from "react-calendly";
import { Container } from "react-bootstrap";

const CalendlyInline = () => {
    // define style by lightMode
    const { lightMode } = useThemeContext();


    return <>
        <Container fluid className="pt-5 mb-3">
            <Container className="pt-1 pb-0 mb-0 ">
                <h3 className="mt-5 mb-3 text-center txt-clr-golden" >Book An Appointment Today!</h3>
            </Container>
            
            <InlineWidget
                iframeTitle="Calendly Scheduling Page"
                pageSettings={{
                    backgroundColor: lightMode ? `rgb(0, 30, 60)` : '#fafdff',
                    hideEventTypeDetails: false,
                    hideGdprBanner: false,
                    hideLandingPageDetails: false,
                    primaryColor: '00a2ff',
                    textColor: '4d5055'
                }}
                styles={{
                    height: '750px',
                }}
                url={process.env.REACT_APP_CALENDLY}
            />
        </Container>
    </>;
}
 
export default CalendlyInline;
