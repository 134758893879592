
import React, { createContext, useContext } from "react";
import usePersistedState from "./usePersistedState";

// register the context
const ThemeContext = createContext({});

/**
 * export custom provider
 * @param {boolean} lightMode
 * @returns
 */
export function ThemeProvider({ children }) {
  /** usePersistedState for storing state in local store */
  // const [lightMode, setLightMode] = useState(false);
  const [lightMode, setLightMode] = usePersistedState("lightmode", false);


  return <>
    <ThemeContext.Provider value={{ lightMode, setLightMode }}>
      {children}
    </ThemeContext.Provider>
  </>;
}

// export a custom hook to use this specific context
export function useThemeContext() {
  return useContext(ThemeContext);
}