
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from "../../assets/styles";
import { getTaxSoftwareText, dealType } from "./helpers/taxSoftware";
import { Container, Col, Row } from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import { Card } from "react-bootstrap";
import { motion } from "framer-motion";
import Stripe from "./buttons/Stripe";

export default function Pricing() {
  const [data, setData] = useState(null);
  const [promo, setPromo] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [promoDate, setPromoDate] = useState("01 Jan 2023");
  const navigate = useNavigate();
  // define styles by lightMode
  const { lightMode } = useThemeContext();
  const { dealStyles, cardTextColor, fontColor } = switchTheme(lightMode);
  const gray700 = "gray-700";
  const sm = 1, lg = 3;
  const pricing = promo ? "-promo" : "";

  function checkPromo() {
    const now = new Date();
    const promoEndTime = Date.parse(`${promoDate} 23:59:59 EST`);
    if (promo !== true && promoEndTime > now) {
      setPromo(true);
    } else if (promo === true && promoEndTime < now) {
      setPromo(false);
      // setData(null)
      setPromoDate("01 Jan 2023");
    }
  }

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("success")) {
      navigate("/confirmation", { state: { status: "success" } });
    }

    if (query.get("canceled")) {
      navigate("/confirmation", { state: { status: "canceled" } });
    }
  });

  // Fetch data on mount
  useEffect(() => {
    if (data === null)
      (async function () {
        try {
          const response = await fetch(process.env.REACT_APP_API);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const res = await response.json();
          setData((currentState) => {
            return (currentState = res);
          });
          setPromoDate((currentState) => {
            return (currentState = res.promo_date);
          });
        } catch (error) {
          console.error("Caught error:", error);
        }
      })();
  }); // Empty dependency array means this runs once when the component mounts

  useEffect(() => {
    const intervalId = setInterval(checkPromo, 1000 * 1); // checks every second
    return () => clearInterval(intervalId);
  }, [promoDate]);

  
  const regularPrice = "Regular Price";
  // Stripe Prices
  const firstDealStripePrice = data ? data.first_deal_stripe_price : process.env.REACT_APP_STRIPE_TAX_PREPARER_PRO;
  const secondDealStripePrice = process.env.REACT_APP_STRIPE_TAX_PREPARER_DELUXE;
  const thirdDealStripePrice = data ? data.third_deal_stripe_price :  process.env.REACT_APP_STRIPE_TAX_PREPARER_PREMIUM;
  const fourthDealStripePrice = data ? data.fourth_deal_stripe_price :  process.env.REACT_APP_STRIPE_TAX_OFFICE;
  const fifthDealStripePrice = data ? data.fifth_deal_stripe_price :  process.env.REACT_APP_STRIPE_MENTORSHIP;
  const sixthDealStripePrice = data ? data.sixth_deal_stripe_price :  process.env.REACT_APP_STRIPE_BOOKKEEPING;

  // Promotions/Deals
  const firstDealDiscount = data ? data.first_deal_discount : 0;
  const secondDealDiscount = data ? data.second_deal_discount : 0;
  const thirdDealDiscount = data ? data.third_deal_discount : 0;
  const fourthDealDiscount = data ? data.fourth_deal_discount : 0;
  const fifthDealDiscount = data ? data.fifth_deal_discount : 0;
  const sixthDealDiscount = data ? data.sixth_deal_discount : 0;

  const taxAffiliateRegularPrice1 = data ? data.first_deal_regular_price : 999;
  const taxAffiliateRegularPrice2 = data ? data.second_deal_regular_price : 1199;
  const taxAffiliateRegularPrice3 = data ? data.third_deal_regular_price : 1499;
  const taxOfficeRegularPrice = data ? data.fourth_deal_regular_price : 499;
  const mentorshipRegularPrice = data ? data.fifth_deal_regular_price : 1199;
  const bookkeepingRegularPrice = data ? data.sixth_deal_regular_price : 79;

  const taxAffiliatePromo1 = taxAffiliateRegularPrice1 - firstDealDiscount;
  const taxAffiliatePromo2 = taxAffiliateRegularPrice2 - secondDealDiscount;
  const taxAffiliatePromo3 = taxAffiliateRegularPrice3 - thirdDealDiscount;
  const taxOfficePromo = taxOfficeRegularPrice - fourthDealDiscount;
  const mentorshipPromo = mentorshipRegularPrice - fifthDealDiscount;
  const bookkeepingPromo = bookkeepingRegularPrice - sixthDealDiscount;

  const taxAffiliate1 = promo ? taxAffiliatePromo1 : taxAffiliateRegularPrice1;
  const taxAffiliate2 = promo ? taxAffiliatePromo2 : taxAffiliateRegularPrice2;
  const taxAffiliate3 = promo ? taxAffiliatePromo3 : taxAffiliateRegularPrice3;
  const taxOffice = promo ? taxOfficePromo : taxOfficeRegularPrice;
  const mentorship = promo ? mentorshipPromo : mentorshipRegularPrice;
  const bookkeeping = promo ? bookkeepingPromo : bookkeepingRegularPrice;

  const earlySpecialHTML = <p style={{ color: "#008080" }}>Offer Expires {promoDate}</p>
  const textWarning = "text-warning";
  const duration = 1.0

  const taxSoftwareText = getTaxSoftwareText(
    promo,
    earlySpecialHTML,
    regularPrice,
    taxOfficeRegularPrice,
    taxAffiliateRegularPrice1,
    taxAffiliateRegularPrice2,
    taxAffiliateRegularPrice3,
    mentorshipRegularPrice,
    bookkeepingRegularPrice
  );

  const ReactCards = () => {
    return <Container>
      <div className="text-center pt-5">
        <h2>Free Software Setup</h2>
        <h5 style={fontColor}>Software & Training in English & Spanish Available</h5>
        <h5>
          <strong>
            Free - How To Apply For an EFIN & EIN{" "}
          </strong>{" "}
          - I Personally Guide You Through{" "}
          <strong>YOUR </strong> Application
        </h5>
      </div>
      <Row
        className={`justify-items-center pricing`}
        xs={1}
        sm={sm}
        lg={lg}
      >
        <Col>
          <motion.div
            className={`${isVisible ? 'visible' : 'hidden'}`}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: duration + .3 }}
          >
            <Card
              className={`bootstrap-card${pricing} shadow ${gray700} rounded text-center mt-2 mb-5`}
              style={dealStyles}
            >
              <Card.Header as={"h2"} style={cardTextColor}>
                {dealType.taxAffiliate1}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"} className={textWarning}>
                  {taxAffiliate1}{" "}
                  <small className={textWarning}>/ yr </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.taxAffiliate1}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <Stripe
                    price={firstDealStripePrice}
                    id={"taxAffiliate1"}
                  />
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
        <Col>
          <motion.div
            className={`${isVisible ? 'visible' : 'hidden'}`}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: duration + .7 }}
          >
            <Card
              className={`bootstrap-card${pricing} shadow ${gray700} rounded text-center mt-2 mb-5`}
              style={dealStyles}
            >
              <Card.Header as={"h2"} style={cardTextColor}>
                {dealType.taxAffiliate2}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"} className={textWarning}>
                  {taxAffiliate2}{" "}
                  <small className={textWarning}>/ yr </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.taxAffiliate2}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <Stripe
                    price={secondDealStripePrice}
                    id={"taxAffiliate2"}
                  />
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
        <Col>
          <motion.div
            className={`${isVisible ? 'visible' : 'hidden'}`}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: duration + .5 }}
          >
            <Card
              className={`bootstrap-card${pricing} shadow ${gray700} rounded text-center mt-2 mb-5`}
              style={dealStyles}
            >
              <Card.Header as={"h2"} style={cardTextColor}>
                {dealType.taxAffiliate3}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"} className={textWarning}>
                  {taxAffiliate3}{" "}
                  <small className={textWarning}>/ yr </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.taxAffiliate3}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <Stripe
                    price={thirdDealStripePrice}
                    id={"taxAffiliate3"}
                  />
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>
      <Row
        className={`justify-items-center pricing`}
        xs={1}
        sm={sm}
        lg={lg}
      >
        <Col>
          <motion.div
            className={`${isVisible ? 'visible' : 'hidden'}`}
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: duration + .3 }}
          >
            <Card
              className={`bootstrap-card${pricing} shadow ${gray700} rounded text-center mt-2 mb-5`}
              style={dealStyles}
            >
              <Card.Header as={"h2"} style={cardTextColor}>
                {dealType.taxOffice}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"} className={textWarning}>
                  {taxOffice}{" "}
                  <small className={textWarning}>/ yr </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.taxOffice}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <Stripe
                    price={fourthDealStripePrice}
                    id={"taxOffice"}
                  />
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
        <Col>
          <motion.div
            className={`${isVisible ? 'visible' : 'hidden'}`}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: duration + .7 }}
          >
            <Card
              className={`bootstrap-card${pricing} shadow ${gray700} rounded text-center mt-2 mb-5`}
              style={dealStyles}
            >
              <Card.Header as={"h2"} style={cardTextColor}>
                {dealType.mentorship}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"} className={textWarning}>
                  {mentorship}{" "}
                  <small className={textWarning}>/ yr </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.mentorship}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <Stripe
                    price={fifthDealStripePrice}
                    id={"mentorship"}
                  />
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
        <Col>
          <motion.div
            className={`${isVisible ? 'visible' : 'hidden'}`}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: duration + .5 }}
          >
            <Card
              className={`bootstrap-card${pricing} shadow ${gray700} rounded text-center mt-2 mb-5`}
              style={dealStyles}
            >
              <Card.Header as={"h2"} style={cardTextColor}>
                {dealType.bookkeeping}
              </Card.Header>
              <Card.Body>
                <Card.Title as={"h1"} className={textWarning}>
                  {bookkeeping}{" "}
                  <small className={textWarning}>/ mo </small>
                </Card.Title>
                <Card.Text as={"h5"}>
                  <ul className="list-unstyled mt-3 mb-4">
                    {taxSoftwareText.bookkeeping}
                  </ul>
                </Card.Text>
                <div className="d-grid position-sticky top-100">
                  <Stripe
                    price={sixthDealStripePrice}
                    id={"bookkeeping"}
                  />
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>
    </Container>
  }

  return (
    <>
      <VisibilitySensor 
        onChange={(visible) => setIsVisible(visible)}
        partialVisibility={true}
        offset={{ top: 100 }}
      >
        <ReactCards />
      </VisibilitySensor>
    </>
  );
}
