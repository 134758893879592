
import { useThemeContext } from "../../../hooks/usethemeContext";
import { switchTheme } from '../../../assets/styles';
import { PopupButton } from "react-calendly";


const CalendlyButton = () => {
    // define style by lightMode
    const { lightMode } = useThemeContext();
    const { buttonColors } = switchTheme(lightMode);
    const colors = {
        background: 'transparent',
        clrTextLight: 'whitesmoke',
        clrTextDark: 'black',
        clrButtonBg1: buttonColors.warning,
        clrButtonBg2: buttonColors.greenish,
    };

    const styles = {
        display: 'inline-block',
        padding: '.75em 2.7em',
        margin: '.5em auto',
        border: lightMode ? 'none' : `solid ${colors.clrButtonBg2} 1px`,
        // border: lightMode ? `solid ${colors.clrButtonBg2} 1px` : 'none',
        borderRadius: '.5em',
        // color: lightMode ? colors.clrButtonBg2 : colors.clrTextLight,
        color: lightMode ? colors.clrTextLight : colors.clrButtonBg2,
        // color: colors.clrButtonBg1,
        // fontWeight: '600',
        backgroundColor: lightMode ? colors.clrButtonBg2 : colors.background,
        // backgroundColor: lightMode ? colors.background : colors.clrButtonBg2,
        // background: 'transparent',
        // fontFamily: ('Arial Light'),
        // background: `linear-gradient(to left, ${colors.clrButtonBg1}, ${colors.clrButtonBg2})`
    };

    return <>
        <div className="">
            <PopupButton
                className={`button-calendly shrink-on-sm-screen ${lightMode ? "" : 'fw-bold'} `}
                iframeTitle="Calendly Scheduling Page"
                pageSettings={{
                    backgroundColor: 'ffffff',
                    hideEventTypeDetails: false,
                    hideGdprBanner: true,
                    hideLandingPageDetails: false,
                    primaryColor: '00a2ff',
                    textColor: '4d5055'
                }}
                rootElement={document.getElementById("root")}
                styles={styles}
                text="Book An Appointment!"
                url={process.env.REACT_APP_CALENDLY}
            />
        </div>
    </>;
}
 
export default CalendlyButton;
