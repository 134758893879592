
import { useThemeContext } from "../hooks/usethemeContext";
import MainBanner from "./sub-components/MainBanner";
import Advantages from './sub-components/Advantages';
import Pricing from "./sub-components/Pricing";
import FunFacts from "./sub-components/FunFacts";
import CompTable from "./sub-components/CompTable";


const BusinessOpportunities = () => {
  const { lightMode } = useThemeContext();
  const info = 'info', light = 'light', dark = 'dark';

    return <>
      <div>
        <section>
          <MainBanner page='software' />
        </section>
        <section id='fun-facts'>
          <FunFacts />
        </section>
        <section id='advantages'>
          <Advantages page='software' />
        </section>
        {/* <section id='comp-table' > */}
        <section id='comp-table' className={`bg-${lightMode ? info : light}`}>
          <br /><br /><br />
          <CompTable />
          <br /><br /><br />
        </section>
        <section id='pricing'>
          <Pricing />
        </section>
      </div>
    </>;
};

export default BusinessOpportunities;